<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 add-company-document-page"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="(redirectRoute && redirectRoute.from) || defaultRedirect"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "documents",
              "unaccepted-documents",
              "unaccepted-documents"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section>
      <ul
        class="clebex-item-section"
        v-for="(document, index) in unacceptedCompanyDocuments"
        :key="index"
      >
        <li class="clebex-item-section-item mail-flex">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <span class="label">
                <span class="highlight">{{ document.name }}</span>
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border mail-flex">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div
                :ref="`parent-content-${document.id}`"
                class="document-content"
                @scroll="onScroll(document.id, $event)"
              >
                <div
                  :ref="`child-content-${document.id}`"
                  v-html="document.content"
                ></div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item mail-flex"
          style="padding-top: 0px;"
        >
          <div
            class="clebex-item-content-wrapper"
            style="display: flex; flex-direction: column;"
          >
            <dl
              class="clebex-item-dl justify-start"
              style="visibility: hidden; height: 0px;"
            >
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    type="checkbox"
                    :disabled="isDisabled(document.id)"
                    v-model="checkedDocuments"
                    :id="document.id"
                    :value="document.id"
                    :name="document.id"
                    @change="acceptDocument(document.id)"
                  />
                  <label :for="document.id"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "documents",
                    "unaccepted-documents",
                    "i-accept"
                  )
                }}
              </dd>
            </dl>
            <div
              style="display: flex; flex-direction: row; margin-bottom: 5px;"
              :style="[
                isDisabled(document.id) &&
                !checkedDocuments.includes(document.id)
                  ? 'visibility: visible'
                  : 'visibility: hidden'
              ]"
            >
              <span>* </span>
              <span style="color: #CB5BA3; margin-left: 5px;">{{
                displayLabelName(
                  "documents.unaccepted-documents.read-until-the-end"
                )
              }}</span>
            </div>
            <button
              v-if="
                isDisabled(document.id) &&
                  !checkedDocuments.includes(document.id)
              "
              class="accept-document-inactive"
            >
              {{
                displayLabelName(
                  "documents",
                  "unaccepted-documents",
                  "i-accept"
                )
              }}
            </button>
            <button
              v-if="
                !isDisabled(document.id) ||
                  checkedDocuments.includes(document.id)
              "
              class="accept-document-active"
              @click="acceptThisDocument(document.id)"
            >
              {{
                displayLabelName(
                  "documents",
                  "unaccepted-documents",
                  "i-accept"
                )
              }}
            </button>
          </div>
        </li>
      </ul>
    </section>
    <template v-slot:footer> </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "CompanyDocuments",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      checkedDocuments: [],
      readDocuments: [],
      defaultRedirect: { name: "r_home", params: null, query: null }
    };
  },
  created() {
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
    this.getUnacceptedCompanyDocuments(this.applicationId).then(response => {
      const ids = response.map(document => document.id);
      this.checkScrollable(ids);
    });
    this.getApplication(this.applicationId);
    window.addEventListener("resize", this.changeWidth);
  },
  watch: {
    applicationId(value) {
      if (value && typeof value !== undefined) {
        this.getUnacceptedCompanyDocuments(value).then(response => {
          const ids = response.map(document => document.id);
          this.checkScrollable(ids);
        });
        this.getApplication(value);
      }
    }
  },
  computed: {
    ...mapState("companyDocuments", [
      "redirectRoute",
      "unacceptedCompanyDocuments"
    ]),
    applicationId() {
      const { applicationId } = this.$route.params;
      return applicationId;
    }
  },
  methods: {
    ...mapActions("companyDocuments", [
      "getUnacceptedCompanyDocuments",
      "acceptCompanyDocuments"
    ]),
    ...mapActions("applications", ["getApplication"]),
    acceptThisDocument(id) {
      const element = document.querySelector(`[id="${id}"]`);
      element.click();
    },
    acceptDocument(id) {
      const data = { company_document_ids: [id] };
      this.acceptCompanyDocuments(data).then(() => {
        if (
          this.checkedDocuments.length ===
          this.unacceptedCompanyDocuments.length
        ) {
          const { name, params, query } =
            (this.redirectRoute && this.redirectRoute.to) ||
            this.defaultRedirect;
          this.$router.push({ name: name, params: params, query: query });
        }
      });
    },
    onScroll(id, event) {
      // if user scroll 90% of div we enable button
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight * 0.9
      ) {
        this.readDocuments.push(id);
      }
    },
    isDisabled(id) {
      return (
        this.checkedDocuments.includes(id) || !this.readDocuments.includes(id)
      );
    },
    checkScrollable(ids) {
      ids.forEach(id => {
        if (!this.$refs[`parent-content-${id}`]) {
          return;
        }
        const parentRect = this.$refs[
          `parent-content-${id}`
        ][0].getBoundingClientRect();
        const childRect = this.$refs[
          `child-content-${id}`
        ][0].getBoundingClientRect();
        let parentBottom =
          parentRect.bottom + window.scrollY + parentRect.height;
        let childBottom = childRect.bottom + window.scrollY + childRect.height;
        if (parentBottom >= childBottom * 0.9) {
          this.readDocuments.push(id);
        }
      });
    },
    changeWidth() {
      setTimeout(() => {
        const ids = this.unacceptedCompanyDocuments.map(
          document => document.id
        );
        this.checkScrollable(ids);
      }, 50);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.changeWidth);
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
<style>
.accept-document-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 455px;
  left: 10px;
  height: 50px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #c2c2c2;
  color: rgb(67, 66, 66);
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  cursor: auto;
}
.accept-document-active {
  left: 50px;
  height: 50px;
  /* UI Properties */
  background: #00c8bf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
}
</style>
